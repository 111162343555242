import { useEffect, useRef, useState } from 'react';
import SVG404 from './404.svg';
import { useRouter } from 'next/router';
import Chapter from '../../components/chapterLayout/chapter';
import { ChapterType } from '../chapter/[id]';
import { NEXTJS_REVALIDATE_PAGE_IN_SECONDS } from '..';
import { getSharedData } from '../../utils/parsers';
import CustomMetaData, {
  CustomMetaDataProps,
} from '../../components/general/customMetaData/CustomMetaData';
import { getAPI } from '../../utils/helpers';

type Props = {
  nextURL: string;
  prevURL: string;
  chapter: ChapterType;
  chapters: ChapterType[];
  metaData: CustomMetaDataProps;
};

const PageNotFound = ({ chapter, nextURL, prevURL, chapters = [], metaData }: Props) => {
  return (
    <>
      <CustomMetaData {...metaData} />
      <Chapter
        forcedNumber={404}
        chapter={chapter}
        chapters={chapters}
        nextURL={nextURL}
        prevURL={prevURL}
      />
    </>
  );
};
// This also gets called at build time
export async function getStaticProps({ params }: any) {
  // params contains the project `id`.
  // If the route is like /project/1, then params.id is 1

  const globalData = await getSharedData();
  const homeRequest = await fetch(`${getAPI()}/homepage`);
  const homepage = await homeRequest.json();

  const projects = globalData.chapters;

  // find project with correct slug

  const project: ChapterType = {
    url: '',
    title: 'Missing <s>Cat</s> Page.',
    featured: true,
    allMedia: [],
    page_count: 1,
    slug: '/',
    pages: [
      {
        media_items: [
          {
            crop: false,
            isZoomable: false,
            media: {
              // base64: '',
              image_width: 850,
              image_height: 1175,
              image: '/page-not-found.png',

              alt_text: '',
              autoplay: false,
              controls: true,
              loop: false,
              caption: '',
              type: { desktop: 'full', phone: 'full' },
              aspect_ratio: {
                image: 'portrait',
              },
            },
          },
        ],
        content: `<p>The owner of a missing cat is asking for help. "My baby has been missing for over a month now, and I want him back so badly," said Mrs. Brown, a 56-year-old woman. Mrs. Brown lives by herself in a trailer park near Clovis. She said that Clyde, her 7-year-old cat, didn't come home for dinner more than a month ago. The next morning he didn't appear for breakfast either. After Clyde missed an extra-special lunch, she called the police. When the policeman asked her to describe Clyde, she told him that Clyde had beautiful green eyes, had all his teeth but was missing half of his left ear, and was seven years old and completely white. She then told the officer that Clyde was about a foot high. A bell went off. "Is Clyde your child or your pet?" the officer suspiciously asked. "Well, he's my cat, of course," Mrs. Brown replied. "Lady, you're supposed to report missing PERSONS, not missing CATS," said the irritated policeman. "Well, who can I report this to?" she asked. "You can't. You have to ask around your neighborhood or put up flyers," replied the officer.</p>
        <p>Mrs. Brown figured that a billboard would work a lot better than an 8"x11" piece of paper on a telephone pole. There was an empty billboard at the end of her street just off the interstate highway. The billboard had a phone number on it. She called that number, and they told her they could blow up a picture of Clyde (from Mrs. Brown's family album) and put it on the billboard for all to see.</p>
        <p>"But how can people see it when they whiz by on the interstate?" she asked. "Oh, don't worry, ma'am, they only whiz by between 2 a.m. and 5:30 a.m. The rest of the day, the interstate is so full of commuters that no one moves." They told her it would cost only $3,000 a month. So she took most of the money out of her savings account and rented the billboard for a month.</p>
        <p>The month has passed, but Clyde has not appeared. Because she has almost no money in savings, Mrs. Brown called the local newspaper to see if anyone could help her rent the billboard for just one more month. She is waiting but, so far, no one has stepped forward.</p>`,
        background_color: '#FFFFFF',
      },
    ],
  };
  // const project = projects.find((p) => p.slug === params.id) as Props;

  // const project = currentProject;

  // Pass project data to the page via props

  // set some FED types

  // DEV NOTE: can be added to the API ,do it maually until that point

  const props: Props = {
    nextURL: '/',
    prevURL: '/',
    chapter: {
      pages: project.pages,
      url: project.url,
      slug: project.slug,
      title: project.title,
      page_count: project.pages.length,
      featured: project.featured,
      allMedia: [],
    },
    chapters: projects,
    metaData: {
      title: homepage.meta_title,
      description: homepage.meta_description,
      image: homepage.meta_image,
    },
  };
  return {
    props: props,
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: NEXTJS_REVALIDATE_PAGE_IN_SECONDS, // In seconds
  };
}

export default PageNotFound;
